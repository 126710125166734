import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { LanguageSwitchDropdown } from './LanguageSwitchDropdown';
import { Message } from '../../framework/src/Message';
import { getTransContent, getTranslationConfig } from '../../components/src/helpers';
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';

const Logo = require("./logo_white.svg");
const avatar = require("./avtarHeader.png");

export function AppHeader(props: any) {
  const showProfileIcon = props.showProfileIcon !== undefined ? props.showProfileIcon : true;


  const { t } = getTranslationConfig();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(avatar);

  const sendMessage = (msg: Message) => {
    msg.addData(getName(MessageEnum.NavigationPropsMessage), props.context?.props);
    props.context?.send(msg);
  };

  const goToUserProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSpaProfile)
    );
    sendMessage(msg);
  };

  const goToLoginPage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    sendMessage(msg);
  };

  const handleProfileClick = () => {
    if (props.onProfileClick) {
      props.onProfileClick();
    } else {
      goToUserProfile();
    }
  };

  useEffect(() => {
    const checkAuthToken = async () => {
      const authToken = localStorage.getItem('authToken');
      setIsLoggedIn(!!authToken);

      const storedProfileUrl = localStorage.getItem("avatarImageUrl");
      if (storedProfileUrl) {
        setProfileImageUrl(storedProfileUrl);
      }
    };

    checkAuthToken();

    const handleStorageChange = () => {
      const updatedProfileUrl = localStorage.getItem("avatarImageUrl");
      if (updatedProfileUrl) {
        setProfileImageUrl(updatedProfileUrl);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [props.forceUpdate]);

  useEffect(() => {
    const storedProfileUrl = localStorage.getItem("avatarImageUrl");
    if (storedProfileUrl) {
      setProfileImageUrl(storedProfileUrl);
    }
  }, [localStorage.getItem("avatarImageUrl")]);

  return (
    <Box style={styles.container as React.CSSProperties}>
      <img src={Logo} alt={""} style={{ width: '105px', height: '43px', objectFit: 'cover' }} />
      <Box display='flex' alignItems='center'>
        {showProfileIcon && isLoggedIn ? (
          <img
            src={props.profileImageUrl || profileImageUrl || avatar}
            alt="User Profile"
            style={styles.profileImage as React.CSSProperties}
            onClick={handleProfileClick}
          />
        ) : (showProfileIcon ? (
          <ButtonComponent>
            <Button
              className='login'
              onClick={() => goToLoginPage()}
            >
              {t['app-header-login']}
            </Button>
          </ButtonComponent>
        ) : null)}
        <LanguageSwitchDropdown forceUpdate={props.forceUpdate} />
      </Box>
    </Box>
  );
}

const ButtonComponent = styled(Button)({
  '& .login': {
    display: 'flex',
    height: '44px',
    padding: '10px 16px',
    borderRadius: '8px',
    background: 'var(--Primary, #398378)',
    fontFamily: 'Ubuntu',
    textTransform: 'none',
    color: '#fff'
  },
});

const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '56px',
    backgroundColor: '#000',
    flexDirection: 'row',
    paddingLeft: '141px',
    paddingRight: '141px',
    display: 'flex'
  },
  text: {
    fontSize: 36,
    fontWeight: '600',
  },
  profileImage: {
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    borderRadius: '50%',
    cursor: 'pointer'
  }
};