import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';

// Customizable Area Start
import moment from "moment";
import momentTz from 'moment-timezone';
import { getAndSaveCurrency } from '../../../components/src/helpers';
import { Product } from '../../../components/src/GalleryBlock.web';

type NameId = {
  id: number,
  name: string
};

export type SubCategory = {
  category: {
    id: number,
    name: string
  },
  subcategory: {
    id: number,
    name: string
  },
  slots: {
    id: 4,
    duration: string,
    price: string,
    start_time: string,
    end_time: string
  }[]
}

interface BookingSlot {
  start_time: string;
  end_time: string;
  booking_date: string;
  service_provider_id: number;
  catalogue_id: number;
  bx_block_categories_slot_id: number;
  status: string;
  customer_id: number;
}

interface Availabilities {
  timeslots: string[];
  dates: string[];
  duration: {
    duration: string;
  };
}

interface TimeslotData {
  id: string;
  name: string;
  availabilities: Availabilities[];
  booked_slots: BookingSlot[];
}

type TimeSlot = {
  start_time: string;
  end_time: string;
  date: string[];
  status: string;
  same_timing: boolean;
};

type UserProfile = {
  id: string;
  attributes: {
    full_name: string;
    open_timing: string;
    spa_timings: TimeSlot[];
    about: string;
    about_us: string;
    phone_number: number | null;
    email: string;
    profile_data: {
      attributes: {
        account_id: number,
        address: string,
        photo: string,
        bio: string,
      }
    }
    profile_photo: {
      url: string
    };
    services: {
      subcategories_with_slots: SubCategory[]
    }[],
    reviews: {
      name: string;
      review: string;
      comment: string;
      created_at: string;
    }[];
    image_url: string,
    average_review: number,
    average_review_string: string,
    carousal_images: {
      id: number,
      url: string
    }[]
  }
};

interface SpaOption {
  id: string;
  label: string;
  spaName: string;
  value: string;
}

interface SearchDataItem {
  data: {
    id: string;
    type: string;
    attributes: {
      account: Array<{
        id: number;
        name: string;
        currency: string | null;
        country: string;
        address: string;
        city: string;
        postal_code: string;
        rating: number;
        rating_votes: number;
      }>;
      services: Array<{
        service_name: string;
        price: number;
        discount: number | null;
        sale_price: number | null;
        category: {
          id: number;
          name: string;
          subcategory: Array<{ id: number; name: string }>;
        };
        image: {
          image_id: number | null;
          image_name: string | null;
          media_type: string | null;
          image_url: string | null;
        };
      }>;
      coupons: Array<{
        id: number;
        title: string;
        description: string | null;
        code: string;
        discount_type: string;
        discount: string;
        valid_from: string;
        valid_to: string;
        min_cart_value: string;
        max_cart_value: string | null;
        created_at: string;
        updated_at: string;
        account_id: number;
        visibility: boolean;
        usage_type: string;
        usage_limit: number | null;
        arabic_title: string | null;
        arabic_description: string | null;
      }>;
    };
  };
}

interface Image {
  id: number;
  url: string;
  type: string;
  filename: string;
  name: string;
}

interface ContentAttributes {
  title: string;
  description: string;
  arabic_title: string;
  arabic_description: string;
  status: boolean;
  page_name: string;
  created_at: string;
  updated_at: string;
  images: Image[] | null;
}

interface Content {
  id: string;
  type: string;
  attributes: ContentAttributes;
}

interface ContentData {
  data: Content[];
}

interface ContactData {
  id: string;
  type: string;
  attributes: ContactAttributes;
}

interface ContactAttributes {
  name: string;
  email: string;
  phone_number: string;
  description: string;
  created_at: string;
  user: string;
}

interface ContactResponse {
  data: ContactData[];
}

interface CategoryAttributes {
  name: string;
  description: string;
  arabic_name: string;
  arabic_description: string;
  images: {
    id: number;
    url: string;
    type: string;
    filename: string;
  }[];
}

interface CategoryData {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}

interface CountryData {
  countries: string[];
}

interface ProfileResponse {
  data: {
    id: string
    attributes: {
      full_name: string;
    };
    profile_photo: {
      url: string;
    };
  }[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openCustomerRescheduleModal?: boolean;
  setCustomerRescheduleModal?: (arg: boolean) => void;
  currentDate?: string[];
  scheduleSlots?: Array<{
    time: string;
    duration: string;
    ordered: boolean;
    isAvailable: boolean;
  }>;
  availableSlots?: string[];
  newDate?: string;
  openTiming?: string | undefined;
  openLoginModal?: boolean;
  setOpenLoginModal?: (arg: boolean) => void;
  openLogoutModal?: boolean;
  setOpenLogoutModal?: (arg: boolean) => void;
  selectedTimeSlot?: { date: string; slot: { time: string; duration: string; ordered: boolean } } | null;
  onSelectTimeSlot?: (date: string, slot: { time: string; duration: string; ordered: boolean } | null) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  venueCarouselIndex: number;
  buttonIndex: number,
  anchorEl: HTMLButtonElement | null,
  isLeftClick: boolean,
  categories: any[],
  venuesData: any[],
  paginatedVenues: any[];
  totalVenues: number;
  imageLoading: boolean,
  userDetails: {
    id: string;
    attributes: {
      full_name: string;
      open_timing: string;
      spa_timings: TimeSlot[];
      about: string;
      about_us: string;
      phone_number: number | null;
      email: string;
      currency: string;
      profile_data: {
        attributes: {
          account_id: number,
          address: string,
          photo: string,
          bio: string
        }
      };
      services: {
        category: any,
        subcategories_with_slots: SubCategory[]
      }[],
      reviews: {
        name: string;
        review: string;
        comment: string;
        created_at: string;
      }[];
      image_url: string,
      average_review: number,
      average_review_string: string,
      carousal_images: {
        id: number,
        url: string
      }[]
    }
  } | null,
  pathParam: number,
  currentSlotIndex: number;
  currentPopperIndex: number;
  datesRange: any[][],
  landingPageData: {
    about_us: {
      description: string
    },
    products: Product[],
    coupons: {
      image_url: string,
      visibility: boolean
    }[]
  },
  reviewsList: {
    id: number;
    attributes: {
      average_review: number,
      average_review_string: string,
      rating: number,
      comment: string,
      created_at: string,
      review_photos: {
        id: string,
        url: string,
        media_type: string
      }[],
      account: {
        attributes: {
          first_name: string,
          full_name: string
        }
      }
    }
    name: string;
    comment: string;
    created_at: string
  }[];
  showCreateReviewModal: any;
  selectedTimeSlot: {
    date: string;
    slot: {
      time: string;
      duration: string;
      ordered: boolean;
    };
  } | null;
  selectedTimeSlotPrice: {
    date: string;
    time: string;
    start_time: string;
    end_time: string;
    duration: string;
    price: string;
    subcategoryName: {
      id: number | undefined;
      name: string | undefined;
    };
    slotId: number;
    accountId: number | undefined;
    catelogueId: string | undefined;
  } | null;
  selectedSubcategoryId: number | null;
  selectedCatalogue: TimeslotData | null;
  availableSlots: string[];
  slotAvailability: {
    isAvailable: boolean;
    isSelected: boolean;
    time: string;
    duration: string;
    ordered: boolean;
  }[];
  newSlotsAvailable: string[];
  catalogueChanged: boolean;
  reorderedDates: string[];
  currentDate: string;
  customerEmail: "",
  timeSlots: string[];
  prevIsAvailable: boolean | null;
  customerDetails: UserProfile | null;
  detectedTimeZone: string;
  openCustomerRescheduleModal: boolean;
  loading: boolean;
  subCatLoading: boolean;
  currentPage: number,
  venuesPerPage: number,
  selectServiceName: {
    id: number,
    name: string
  },
  searchResult: any[];
  searchName: string;
  spaLocation: string;
  searchId: string | null;
  searchData: SearchDataItem[];
  spaOptions: SpaOption[];
  locationOptions: string[];
  page: number
  pageSize: number;
  hasMore: boolean;
  isOpen: boolean;
  showFilterScreen: boolean,
  priceRange: [number, number];
  originalSearchData: SearchDataItem[];
  selectedServices: string[];
  selectedCategories: string[];
  city: string;
  selectedImage: number;
  contentData: ContentData;
  contactData: ContactResponse;
  maxPrice: number;
  showAllResults: boolean,
  filteredData: SearchDataItem[];
  currentPlanStatus: string;
  categoryData: CategoryData
  openLoginModal: boolean;
  countryData: CountryData;
  selectedCountry: string;
  profileResponse: ProfileResponse;
  activeStep: number;
  timeslotError: string;
  nextPath:string | null;
  openLogoutModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  currency: string = '';
  apiGetCategoriesCallId = '';
  apiGetUserDetailsCallId = '';
  apiGetContactDetailsCallId = "";
  apiGetCataloguesDetailsCallId = '';
  apiPostCustomerDetailsCallId = '';
  getSubCategoriesCallId = '';
  getSingleCategoriesCallId = "";
  apiGetLandingPageDataCallId = '';
  apiGetUserReviewCallId = '';
  apiCreateUserReviewCallId = '';
  apiGetCustomerDetailsCallId = '';
  apiIDSearchSpaByCallId = "";
  apiContentManagementCallId = " ";
  apiGetCurrentPlanDetailsCallId = "";
  apiGetSpaDetailsByCountryCallId = "";
  apiGetSpaDetailsBasedOnCountryCallId = "";
  pathParam = this.props.navigation.getParam('navigationBarTitleText', 0);
  private formattedSlotsCache: { [key: string]: any[] } = {};
  resolveSearchPromise: (value: void | PromiseLike<void>) => void = () => { };
  searchTimeoutId: any = null;
  unregisterBackHandler: (() => void) | null;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.formattedSlotsCache = {};
    this.searchTimeoutId = null;

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      venueCarouselIndex: 0,
      buttonIndex: 0,
      anchorEl: null,
      isLeftClick: false,
      categories: [],
      venuesData: [],
      paginatedVenues: [],
      totalVenues: 0,
      userDetails: null,
      customerDetails: null,
      pathParam: 0,
      currentSlotIndex: 0,
      currentPopperIndex: 0,
      datesRange: Array.from(new Array(50), () => []),
      imageLoading: false,
      landingPageData: {
        about_us: {
          description: ''
        },
        products: [],
        coupons: []
      },
      reviewsList: [],
      showCreateReviewModal: false,
      selectedTimeSlot: null,
      selectedTimeSlotPrice: null,
      selectedSubcategoryId: null,
      selectedCatalogue: null,
      availableSlots: [],
      slotAvailability: [],
      newSlotsAvailable: [],
      catalogueChanged: false,
      reorderedDates: [],
      currentDate: "",
      customerEmail: "",
      timeSlots: [],
      prevIsAvailable: null,
      detectedTimeZone: 'Asia/Kolkata',
      openCustomerRescheduleModal: false,
      loading: false,
      subCatLoading: true,
      currentPage: 1,
      venuesPerPage: 9,
      selectServiceName: {
        id: 0,
        name: ''
      },
      searchResult: [],
      searchName: "",
      spaLocation: "",
      searchId: null,
      searchData: [],
      originalSearchData: [],
      filteredData: [],
      spaOptions: [],
      locationOptions: [],
      page: 1,
      pageSize: 10,
      hasMore: true,
      isOpen: false,
      showFilterScreen: false,
      priceRange: [0, 500],
      selectedServices: [],
      selectedCategories: [],
      city: "",
      selectedImage: 0,
      contentData: { data: [] },
      contactData: { data: [] },
      maxPrice: 0,
      showAllResults: true,
      currentPlanStatus: "",
      categoryData: {
        id: '',
        type: '',
        attributes: {
          name: '',
          description: '',
          images: [],
          arabic_name: "",
          arabic_description: ""
        },
      },
      openLoginModal: false,
      countryData: {
        countries: []
      },
      selectedCountry: "",
      profileResponse: {
        data: [
          {
            id: "",
            attributes: {
              full_name: ""
            },
            profile_photo: {
              url: ""
            }
          }
        ]
      },
      activeStep: 1,
      timeslotError: "",
      nextPath: null,
      openLogoutModal:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    const navigationPayloadMessage = getName(MessageEnum.NavigationPayLoadMessage);

    if (message.id === navigationPayloadMessage) {
      const currentNavData = message.getData(navigationPayloadMessage)
      currentNavData === 'showReviewPrompt' && this.setState({ showCreateReviewModal: true });
    }

    if (apiRequestCallId === this.apiIDSearchSpaByCallId) {
      this.handleApiResponse(responseJson);
    }

    if (apiRequestCallId === this.apiGetSpaDetailsByCountryCallId) {
      this.setState({
        countryData: responseJson,
      })
      setStorageData('countryDataInfo', JSON.stringify({
        countryData: this.state.countryData.countries,
      }));
    }

    if (apiRequestCallId === this.getSingleCategoriesCallId) {        
      this.setState({
        categoryData: responseJson.info.data,
      })
    }

    if (apiRequestCallId === this.getSubCategoriesCallId) {
      if (responseJson.error || responseJson.message === 'Spas are not available for this category') {
        this.setState({
          venuesData: [],
          imageLoading: true,
          subCatLoading: false,
          totalVenues: 0,
        });
      } else if (responseJson.data && responseJson.data.length > 0) {
        this.setState({
          venuesData: responseJson.data,
          imageLoading: true,
          subCatLoading: false,
          totalVenues: responseJson.data.length,
        }, () => {
          this.updatePaginatedVenues();
        });
      }
    }

    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.apiGetCategoriesCallId) {
        this.setState({ categories: responseJson.data })
      }
      
      if (apiRequestCallId === this.apiGetUserDetailsCallId) {
        this.currency = getAndSaveCurrency(responseJson);
        this.setState({ userDetails: responseJson.data });
      }
      if (apiRequestCallId === this.apiGetLandingPageDataCallId) {
        this.setState({ landingPageData: responseJson.data })
      }
      if (apiRequestCallId === this.apiGetUserReviewCallId) {
        this.setState({
          reviewsList: responseJson.data?.data?.reverse() || []
        })
      }
      if (apiRequestCallId === this.apiContentManagementCallId) {
        this.setState({
          contentData: responseJson
        })
      }
      if (apiRequestCallId === this.apiCreateUserReviewCallId) {
        this.setState({ landingPageData: responseJson.data })
      }
      if (apiRequestCallId === this.apiGetCataloguesDetailsCallId) {
        const { id, attributes } = responseJson.data;
        const { name, availabilities, booked_slots } = attributes;
        if (availabilities && availabilities.length > 0) {
          const processedAvailabilities = availabilities.map((availability: { timeslots: any; dates: any; duration: any }) => ({
            timeslots: availability.timeslots,
            dates: availability.dates,
            duration: availability.duration
          }));

          this.setState({
            selectedCatalogue: {
              id,
              name,
              booked_slots,
              availabilities: processedAvailabilities
            }
          }, () => {
            this.forceUpdate();
          });
        }
      }
      if (apiRequestCallId === this.apiGetCustomerDetailsCallId) {
        const customerDetails = responseJson.data;
        const profilePic = customerDetails?.attributes?.profile_photo?.url;

        this.setState({ customerDetails });
        setStorageData('avatarImageUrl', profilePic);
      }
      if (apiRequestCallId === this.apiGetContactDetailsCallId) {
        this.setState({
          contactData: responseJson,
          currentPlanStatus: ''
        })
        setStorageData('contactInfo', JSON.stringify({
          contactData: this.state.contactData,
          currentPlanStatus: this.state.currentPlanStatus
        }));
      }
      if (apiRequestCallId === this.apiGetCurrentPlanDetailsCallId) {
        const currentStatus = responseJson.data?.[0]?.status;

        setStorageData('contactInfo', JSON.stringify({
          contactData: this.state.contactData,
          currentPlanStatus: currentStatus
        }));
      }
      if (apiRequestCallId === this.apiGetSpaDetailsBasedOnCountryCallId) {
        this.setState({
          profileResponse: responseJson,
          subCatLoading: false
        })
      }
    }


    if (errorReponse) {
      responseJson ?
        this.parseApiCatchErrorResponse(responseJson.message) :
        this.showAlert(configJSON.commonErrorText, configJSON.commonErrorDescription);
    }

    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToCategoryVenues(id: number) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSubCategoriesMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);

    this.send(msg);

    window.scrollTo(0, 0);
  };

  goToVenue(id: string) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationVenueMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), id);

    this.send(msg);
  };

  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToSpaRegistration() {
    const message: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpSPAInitMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), { userRole: 'Spa' });

    this.send(message);
  }

  goToReviews() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationReviewsMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationScreenNameMessage), this.pathParam);

    this.send(msg);
  };

  async getUserDetails() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${this.pathParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getCustomerDetails() {
    const userID = await getStorageData('user_id');
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCustomerDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userDetailsAPIEndPoint}?id=${userID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLandingPageData() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetLandingPageDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.landingPageAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSliderClick(event: React.MouseEvent<HTMLElement>, backBtnRef: HTMLElement, nextBtnRef: HTMLElement) {
    const isLeftClick = event.screenX < window.innerWidth / 2;
    const targetRef = isLeftClick ? backBtnRef : nextBtnRef;

    targetRef.click();

    const carouslImagesLength = this.state.userDetails?.attributes?.carousal_images.length;

    const currentIndex = this.state.venueCarouselIndex;
    const prevSlide = currentIndex > 0 && currentIndex - 1;
    const nextSlide = currentIndex + Number(currentIndex < Number(carouslImagesLength) - 1);

    this.setState({ venueCarouselIndex: Number(isLeftClick ? prevSlide : nextSlide) });
  };

  getSubCategoriesData = () => {
    return new Promise((resolve, reject) => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getSubCategoriesCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_dashboard4/get_category_spas?id=${this.pathParam}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  getSingleCategoriesData = () => {    
    return new Promise((resolve, reject) => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getSingleCategoriesCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_categories/categories/${this.pathParam}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAPIMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    });
  };

  calculateDotsDimensions() {
    const slidesNumber = this.state.userDetails?.attributes?.carousal_images?.length;
    const highSlideEdge = 6;
    const lowSlideEdge = 4;
    // Handle case if there is only one slide
    const multiplier = slidesNumber && slidesNumber > 1 ? (highSlideEdge - lowSlideEdge) / (slidesNumber - 1) : 0;

    return new Array(slidesNumber)
      .fill(highSlideEdge)
      .map((_, index) => highSlideEdge - Math.abs(this.state.venueCarouselIndex - index) * multiplier);
  }

  getResultVouchers() {
    return this.state.landingPageData.coupons
      .filter((coupon) => coupon.visibility === true)
      .map((coupon) => coupon.image_url)
      .filter((image) => image);
  }

  async getUserReviews() {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.reviewApiEndPoint}?spa_id=${this.pathParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async createUserReview(formData: any) {
    const authToken = await getStorageData('authToken');
    const headers = {
      "token": authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateUserReviewCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reviewApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async gotoLogin() {
    const authToken = await getStorageData('authToken');

    if (!authToken) {
      const message: Message = new Message(
        getName(MessageEnum.NavigationEmailLogInMessage)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
        goBack: 'LandingPageVenue',
        param: this.pathParam
      });
      this.send(message);
    }
  }

  setShowCreateReviewModal(value: boolean) {
    this.setState({ showCreateReviewModal: value })
  }


  storeSelectedTimeSlot = (price: string, id: number) => {
    const { services } = this.state.userDetails?.attributes || {};
    const { selectedTimeSlot } = this.state;
    const { attributes } = this.state.userDetails || {};
    const accountId = attributes?.profile_data?.attributes?.account_id;
    const catelogueId = this.state.selectedCatalogue?.id;

    if (selectedTimeSlot) {
      const subcategoryObject = services?.find(service =>
        service.subcategories_with_slots.some(subcategory =>
          subcategory.slots.some(slot => slot.price === price)
        )
      )?.subcategories_with_slots.find(subcategory =>
        subcategory.slots.some(slot => slot.price === price)
      )?.subcategory;

      const [startTime, endTime] = selectedTimeSlot.slot.time.split(' - ');

      const slotInfo = {
        date: selectedTimeSlot.date,
        time: selectedTimeSlot.slot?.time,
        start_time: startTime,
        end_time: endTime,
        duration: selectedTimeSlot.slot?.duration,
        price: price,
        subcategoryName: {
          id: subcategoryObject?.id,
          name: subcategoryObject?.name,
        },
        slotId: id,
        accountId: accountId,
        catelogueId: catelogueId
      };

      setStorageData('selectedTimeSlotPrice', JSON.stringify(slotInfo));
      this.setState({ selectedTimeSlotPrice: slotInfo }, () => {
      });
    }
  };

  formatTime(time: string): string {
    const upperCaseTime = time.toUpperCase();
    if (upperCaseTime.includes('AM') || upperCaseTime.includes('PM')) {
      return upperCaseTime;
    }

    const [hours, minutes] = time.split(':');
    let parsedHours = parseInt(hours);
    const period = parsedHours >= 12 ? 'PM' : 'AM';
    parsedHours = parsedHours % 12 || 12;
    return `${parsedHours.toString().padStart(2, '0')}:${minutes} ${period}`;
  }


  initializeSelectedSubcategory = async () => {
    const { services } = this.state.userDetails?.attributes || {};

    if (services && services.length > 0 && services[0].subcategories_with_slots.length > 0) {
      const initialService = services[0].subcategories_with_slots[0];
      this.handleButtonClick(0, initialService.subcategory.id, initialService.subcategory);
      const firstSubcategoryId = services[0].subcategories_with_slots[0].subcategory.id;
      this.setState({
        selectedSubcategoryId: firstSubcategoryId,
        buttonIndex: 0
      }, () => {
        setStorageData('selectedID', JSON.stringify(firstSubcategoryId));
        this.getCataloguesDetails(firstSubcategoryId);
      });
    }
  }

  getCataloguesDetails = async (subcategoryId: number) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCataloguesDetailsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cataloguesApiEndPoint}/${subcategoryId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleButtonClick = async (index: number, id: number, name: { id: number, name: string }) => {
    const { selectedCatalogue } = this.state;
    const newSlotsAvailables = selectedCatalogue ? selectedCatalogue.availabilities[0]?.timeslots : [];

    await new Promise<void>((resolve) => {
      this.setState({
        buttonIndex: index,
        selectedSubcategoryId: id,
        newSlotsAvailable: newSlotsAvailables,
        selectServiceName: name
      }, resolve);
    });

    setStorageData('selectedID', JSON.stringify(id));
    await this.getCataloguesDetails(id);
  };

  gotoBookingDetails = async (id: number | undefined) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationBookingDetails)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    this.send(message);
  };

  setCatalogueChanged = (value: boolean) => this.setState({ catalogueChanged: value });

  handleProfileClick = () => {
    this.props.navigation.navigate('SpaUserProfile');
  };

  updateReorderedDates = () => {
    const { selectedCatalogue } = this.state;

    if (!selectedCatalogue || !selectedCatalogue.availabilities) {
      this.setState({ reorderedDates: [], currentDate: '' });
      return;
    }

    const catalogueDates = selectedCatalogue.availabilities[0].dates;
    const catalogueDays = catalogueDates.map(date => new Date(date).getDay());

    const todays = new Date();
    const endDate = new Date(todays.getTime() + 90 * 24 * 60 * 60 * 1000);

    let datesRange = [];
    for (let d = todays; d <= endDate; d.setDate(d.getDate() + 1)) {
      if (catalogueDays.includes(d.getDay())) {
        datesRange.push(`${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`);
      }
    }

    datesRange = datesRange.filter(date => new Date(date).getDate() !== 11);

    const initialDate = datesRange.length > 0 ? datesRange[0] : '';

    this.setState({ reorderedDates: datesRange, currentDate: initialDate });
  }


  formatBookedSlots(bookedSlots: any[]) {
    return bookedSlots
      .filter(slot => slot.status === 'booked')
      .map(slot => {
        const parseTime = (timeStr: string) => {
          if (timeStr.endsWith(' +00:00')) {
            return moment(timeStr.slice(0, -6));
          } else {
            return moment(timeStr);
          }
        };

        const startTime = parseTime(slot.start_time);
        const endTime = parseTime(slot.end_time);

        if (!startTime.isValid() || !endTime.isValid()) {
          return {
            booking_date: slot.booking_date,
            time_range: 'Invalid Date - Invalid Date'
          };
        }

        const formatTime = (date: moment.Moment) => {
          return date.format('h:mm A');
        };

        return {
          booking_date: slot.booking_date,
          time_range: `${formatTime(startTime)} - ${formatTime(endTime)}`
        };
      });
  }

  gotoBookingDetail = async (id = "preview") => {
    if (!this.state.selectedTimeSlotPrice) {
      this.setState({ timeslotError: "Please select booking slot" });
      setTimeout(() => {
        this.setState({ timeslotError: "" });
      }, 3000);
      return;
    }

    this.setState({ timeslotError: "" });

    const { userDetails } = this.state;
    if (!userDetails?.attributes) {
      return;
    }

    const { attributes } = userDetails;
    const bookingInfo = {
      full_name: attributes.full_name,
      phone_number: attributes.phone_number,
      email: attributes.email,
      image_url: attributes.image_url,
      currency: attributes.currency,
      complete_attributes: attributes.profile_data.attributes,
    };

    try {
      await setStorageData('bookingInfo', JSON.stringify(bookingInfo));

      const message = new Message(
        getName(MessageEnum.NavigationBookingDetails)
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
      this.send(message);
    } catch (error) {
    }
  };

  async detectTimeZone() {
    let userTimeZone = momentTz.tz.guess();

    try {
      const response = await fetch('https://worldtimeapi.org/api/ip');
      const data = await response.json();
      userTimeZone = data.timezone;
    } catch (error) {
      userTimeZone = 'Asia/Kolkata';
    }

    this.setState({ detectedTimeZone: userTimeZone });
  }

  filterPassedSlots = (slots: any[], newDate: string, openTiming: string | undefined) => {
    if (!openTiming) {
      return slots;
    }
    const [openTime, closingTime] = openTiming.split(' - ');
    const now = momentTz().tz(this.state.detectedTimeZone);
    const selectedDateTime = momentTz(newDate).tz(this.state.detectedTimeZone);
    const closingTimeMoment = selectedDateTime.clone().set({
      hour: moment(closingTime, 'hh:mm a').get('hour'),
      minute: moment(closingTime, 'hh:mm a').get('minute'),
      second: 0,
      millisecond: 0
    });

    if (selectedDateTime.isBefore(now, 'day')) {
      return [];
    }

    if (selectedDateTime.isSame(now, 'day')) {
      if (now.isAfter(closingTimeMoment)) {
        return [];
      }
      return slots.filter(slot => {
        const [startTime] = slot.time.split(' - ');
        const slotTime = selectedDateTime.clone().set({
          hour: moment(startTime, 'hh:mm a').get('hour'),
          minute: moment(startTime, 'hh:mm a').get('minute'),
          second: 0,
          millisecond: 0
        });
        return slotTime.isAfter(now);
      });
    }

    // For future dates, return all slots
    return slots;
  };

  updatePaginatedVenues = () => {
    const { currentPage, venuesPerPage, venuesData } = this.state;
    const indexOfLastVenue = currentPage * venuesPerPage;
    const indexOfFirstVenue = indexOfLastVenue - venuesPerPage;
    const paginatedVenues = venuesData.slice(indexOfFirstVenue, indexOfLastVenue);
    this.setState({ paginatedVenues });
  }

  getFormattedSlots(): any[] {
    const { selectedCatalogue } = this.state;
    const catalogueKey = selectedCatalogue?.booked_slots?.map(slot => slot.booking_date).join(',') || '';

    if (!this.formattedSlotsCache[catalogueKey]) {
      this.formattedSlotsCache[catalogueKey] = this.formatBookedSlots(selectedCatalogue?.booked_slots || []);
    }

    return this.formattedSlotsCache[catalogueKey];
  }

  normalizeTimeFormat(time: string): string {
    time = time.trim().toUpperCase();
    const match = time.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/);
    if (!match) return time;

    let [, hours, minutes, period] = match;
    let hourNum = parseInt(hours);
    if (period === 'PM' && hourNum !== 12) hourNum += 12;
    if (period === 'AM' && hourNum === 12) hourNum = 0;

    return `${hourNum.toString().padStart(2, '0')}:${minutes}`;
  }

  isSlotBooked(slotTime: string, newDate: string): boolean {
    const normalizedSlotTime = this.normalizeTimeFormat(slotTime);
    const formattedSlots = this.getFormattedSlots();

    return formattedSlots.some((bookedSlot: { booking_date: string; time_range: string; }) => {
      const [bookedStart, bookedEnd] = bookedSlot.time_range.split(' - ');
      const normalizedBookedStart = this.normalizeTimeFormat(bookedStart);
      const normalizedBookedEnd = this.normalizeTimeFormat(bookedEnd);

      return bookedSlot.booking_date === newDate &&
        (normalizedSlotTime >= normalizedBookedStart && normalizedSlotTime < normalizedBookedEnd);
    });
  }

  async searchBySpaName(name: { name?: string; location?: string | null }): Promise<void> {

    const headers = {
      'Content-Type': configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiIDSearchSpaByCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchSpaName
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(name)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return new Promise((resolve) => {
      this.resolveSearchPromise = resolve;
    });
  }

  handleSpaSearch = async (searchName: string, spaLocation: string) => {
    if (searchName.length < 2 && spaLocation.length < 2) {
      return;
    }

    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }

    this.searchTimeoutId = setTimeout(async () => {
      this.setState({ loading: true });
      try {
        const formData = { name: searchName, location: spaLocation };
        await this.searchBySpaName(formData);
      } catch (error) {
      } finally {
        this.setState({ loading: false });
      }
    }, 500);
  };

  checkIfOpen = async () => {
    const { open_timing } = this.state.userDetails?.attributes || {};
    if (!open_timing) {
      this.setState({ isOpen: false });
      return;
    }

    const [openTime, closeTime] = open_timing.split(' - ');
    if (!openTime || !closeTime) {
      this.setState({ isOpen: false });
      return;
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = momentTz().tz(userTimeZone);

    const open = momentTz.tz(`${now.format('YYYY-MM-DD')} ${openTime}`, 'YYYY-MM-DD h:mm a', userTimeZone);
    const close = momentTz.tz(`${now.format('YYYY-MM-DD')} ${closeTime}`, 'YYYY-MM-DD h:mm a', userTimeZone);

    if (close.isBefore(open)) {
      close.add(1, 'day');
    }

    const isOpen = now.isBetween(open, close, null, '[]');
    this.setState({ isOpen });
  }


  handleNavigate = (route: string) => {
    window.location.href = `/${route}`;
  };

  goToSearchResult = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationSearchResultMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleSearchAndNavigate = async () => {
    if ((this.state.searchName && this.state.searchName.trim() !== '') || (this.state.spaLocation && this.state.spaLocation.trim() !== '')) {
      const searchName = this.state.searchName || '';
      const spaLocation = this.state.spaLocation || '';
      this.handleSpaSearch(searchName, spaLocation);
      this.goToSearchResult()
    }
  }

  transformSearchResults(search: any[]): any[] {
    return search
      .map((result) => {
        if (result.data.attributes.account.length === 0) {
          return null;
        }
        const account = result.data.attributes.account[0];
        return {
          id: account.id,
          label: account.name,
          spaName: account.name,
          country: account.country,
          city: account.city,
        };
      })
      .filter(Boolean);
  }

  getLocationOptions(transformedResponse: any[]): string[] {
    let locationOptions: string[] = [];

    transformedResponse.forEach((item) => {
      if (item.country) locationOptions.push(item.country.toLowerCase());
      if (item.city) locationOptions.push(item.city.toLowerCase());
    });

    return Array.from(new Set(locationOptions)).filter(Boolean);
  }

  getSpaOptions(search: any[]): any[] {
    return search.flatMap((item) => {
      if (item.data.attributes.account.length === 0) {
        return [];
      } else {
        return item.data.attributes.services.map((service: { service_name: any; }) => ({
          id: item.data.id,
          label: service.service_name,
          country: item.data.attributes.account[0].country,
          city: item.data.attributes.account[0].city
        }));
      }
    });
  }

  filterSearchResults(search: any[]): any[] {
    if (this.state.searchName && this.state.spaLocation) {
      return search.filter((item) => {
        const account = item.data.attributes.account[0];
        const service = item.data.attributes.services[0];
        if (account && service && this.state.spaLocation) {
          return (
            service.service_name.toLowerCase().includes(this.state.searchName.toLowerCase()) &&
            (account.country.toLowerCase().includes(this.state.spaLocation.toLowerCase()) ||
              account.city.toLowerCase().includes(this.state.spaLocation.toLowerCase()))
          );
        }
        return false;
      });
    } else if (this.state.searchName) {
      return search.filter((item) => {
        const service = item.data.attributes.services[0];
        return service.service_name.toLowerCase().includes(this.state.searchName.toLowerCase());
      });
    } else if (this.state.spaLocation) {
      return search.filter((item) => {
        const account = item.data.attributes.account[0];
        return account && this.state.spaLocation?.toLowerCase() && (
          account.country.toLowerCase().includes(this.state.spaLocation.toLowerCase()) ||
          account.city.toLowerCase().includes(this.state.spaLocation.toLowerCase())
        );
      });
    }
    return search;
  }

  handleApiResponse(responseJson: any) {
    if (responseJson && responseJson.results && Array.isArray(responseJson.results)) {
      const search = responseJson.results;
      const spaOptions = this.getSpaOptions(search);
      const locationOptionsData = this.getLocationOptions(spaOptions);
      const filteredResults = this.filterSearchResults(search);

      sessionStorage.setItem('searchResults', JSON.stringify(filteredResults));

      this.setState({
        searchResult: search,
        spaOptions: spaOptions,
        locationOptions: locationOptionsData,
      }, () => {
        if (this.resolveSearchPromise) {
          this.resolveSearchPromise();
        }
      });
    }
  }

  handleSpaNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchName = event.target.value;
    const filteredData = this.filterResultsBySearchName(searchName);
    this.setState({ searchName, filteredData, showAllResults: false });
  };


  filterResultsBySearchName = (searchName: string) => {
    const { originalSearchData } = this.state;
    let filteredData = [...originalSearchData];

    if (searchName) {
      filteredData = filteredData.filter((item) => {
        const itemName = item.data.attributes.account[0]?.name?.toLowerCase();
        const serviceNames = item.data.attributes.services.map((service) => service.service_name.toLowerCase());
        const itemCity = item.data.attributes.account[0]?.city?.toLowerCase();
        const itemCountry = item.data.attributes.account[0]?.country?.toLowerCase();

        return (
          itemName?.includes(searchName.toLowerCase()) ||
          serviceNames.some((serviceName) => serviceName.includes(searchName.toLowerCase())) ||
          itemCity?.includes(searchName.toLowerCase()) ||
          itemCountry?.includes(searchName.toLowerCase())
        );
      });
    }

    return filteredData;
  };


  filterData = () => {
    const { searchData, selectedServices, selectedCategories, city, priceRange } = this.state;

    let filteredData = searchData.filter((item) => {
      if (!item.data.attributes.account || !item.data.attributes.account[0] || !item.data.attributes.account[0].city) {
        return false;
      }
      const itemCity = item.data.attributes.account[0].city.toLowerCase();
      return itemCity.includes(city.toLowerCase());
    });

    if (selectedServices.length > 0) {
      filteredData = filteredData.filter((item) => {
        const services = item.data.attributes.services;
        return services.some((service) => selectedServices.includes(service.service_name));
      });
    }

    if (selectedCategories.length > 0) {
      filteredData = filteredData.filter((item) => {
        const services = item.data.attributes.services;
        return services.some((service) =>
          selectedCategories.includes(service.category.name.charAt(0).toUpperCase() + service.category.name.slice(1))
        );
      });
    }

    if (priceRange) {
      filteredData = filteredData.filter((item) => {
        const services = item.data.attributes.services;
        return services && services.some((service) => service.price >= priceRange[0] && service.price <= priceRange[1]);
      });
    };
    return filteredData;
  };

  getCategoryNames = (): string[] => {
    const { searchData } = this.state;
    const categoryNames: string[] = [];

    searchData.forEach((item) => {
      item.data.attributes.services.forEach((service) => {
        const capitalizedCategoryName = service.category.name.charAt(0).toUpperCase() + service.category.name.slice(1);
        if (!categoryNames.includes(capitalizedCategoryName)) {
          categoryNames.push(capitalizedCategoryName);
        }
      });
    });

    return categoryNames;
  };

  handleApplyFilters = (filters: {
    priceRange: [number, number];
    selectedServices: string[];
    selectedCategories: string[];
    city: string;
  }) => {
    this.setState(
      {
        priceRange: filters.priceRange,
        selectedServices: filters.selectedServices,
        selectedCategories: filters.selectedCategories,
        city: filters.city,
        showFilterScreen: false,
        showAllResults: false,
      },
      () => {
        this.setState({ filteredData: this.filterData() });
      }
    );
  };

  handleClearFilters = () => {
    const { maxPrice } = this.state;
    this.setState(
      {
        priceRange: [0, maxPrice],
        selectedServices: [],
        selectedCategories: [],
        city: '',
        showAllResults: true,
        showFilterScreen: false,
      },
      () => {
        this.setState({ filteredData: this.filterData() });
      }
    );
  };


  handleSlideClick = (index: number) => {
    this.setState({ selectedImage: index });
  }

  async getContentManagementDetails() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiContentManagementCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentManagement
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getContentByPageName = (pageName: string) => {
    if (this.state.contentData && this.state.contentData.data) {
      return this.state.contentData.data.find((content) => content.attributes.page_name === pageName) || null;
    }
    return null;
  }

  getJoinUsContent = () => this.getContentByPageName("Join Us");
  getWhyChooseUsContent = () => this.getContentByPageName("Why Choose Us?");
  getWhoWhatWeAre = () => this.getContentByPageName("Who we are and what we do");

  async getContactDetails() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetContactDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.contactDetailsAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getSpaDetailsByCountry() {

    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSpaDetailsByCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetSpaDetailsByCountry}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getCrruntPlanDetails() {
    const authToken = await getStorageData('authToken');

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCurrentPlanDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.currentPlanAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.userDetailsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setOpenLoginModal = (value: boolean) =>
    this.setState({ openLoginModal: value });

  async getSpasBasedOnCountry(country: string): Promise<void> {
    const headers = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetSpaDetailsBasedOnCountryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPointGetSpaDetailsBasedOnCountry}?country=${country}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.landingPageApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goSpasByCountry() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSpasByCountry)
    );
    this.send(msg);
  };

  handleStepClick = (stepId: number) => {
    this.setState({ activeStep: stepId });
  };

  goToContactUsPage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationContactUsPage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  goToLearnMorePage() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLearnMorePage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  truncateText(text: string, maxWords: number) {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  }

  getLanguageBasedStyle<T>(isArabic: boolean | undefined, arabicValue: T, defaultValue: T): T {
    return isArabic ? arabicValue : defaultValue;
  }

  getOpenTiming = () => {
    const spaTimings = this.state.userDetails?.attributes.spa_timings;  
    if (!spaTimings) {
      return 'Close';
    }
  
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const currentDateString = currentDate.toISOString().split('T')[0];
  
    for (const timing of spaTimings) {
      if (timing.date.includes(currentDateString)) {
        return `${timing.start_time} - ${timing.end_time}`;
      }
    }
  
    return 'Close';
  }

  setOpenLogoutModal = (value: boolean) =>
    this.setState({ openLogoutModal: value });
  
  // Customizable Area End
}
